import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import left from "../../Assets/Images/Arrow-l.svg";
import right from "../../Assets/Images/Arrow-r.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

function SectionThree({ isProject }) {
  const [sliderRef, setSliderRef] = useState(null);
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState();
  useEffect(() => {
    toggleLanguage();
  }, []);
  const handlePrevious = () => {
    sliderRef.slickPrev();
  };

  const handleNext = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    autoplay: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: true,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          autoplay: true,
          infinite: false,
          speed: 500,
        },
      },
    ],
  };

  const toggleLanguage = () => {
    const newLanguage = localStorage.getItem("language");
    setSelectedLanguage(newLanguage);
  };
  return (
    <div className="carousel mx-[2rem]  lg:mx-[7rem]">
      <div className="flex justify-between items-end py-10">
        <div className="relative">
          <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
            {t("Related Projects")}
          </h1>
        </div>
        <div dir="ltr" className="slide_direction flex gap-2 justify-end">
          <div className="left" onClick={handlePrevious}>
            <img
              src={left}
              alt=""
              className="w-[10vw] h-[10vw] xl:w-[3vw] xl:h-[3vw] cursor-pointer"
            />
          </div>
          <div className="right" onClick={handleNext}>
            <img
              src={right}
              alt=""
              className="w-[10vw] h-[10vw] xl:w-[3vw] xl:h-[3vw] cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="slider-container" id="slider" dir="ltr">
        <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
          {isProject?.map((item, index) => (
            <Link key={index} to={"/project/" + item.id}>
              <div
                key={item.id}
                className="flex justify-between gap-[2rem] lg:gap-10 overflow-x-auto lg:overflow-hidden"
                dir="auto"
              >
                <div
                  data-aos="fade-left"
                  className="relative group flex flex-col items-start gap-[15px] w-full lg:w-[26.3vw] hover-overlay "
                >
                  <img
                    src={item.image_url}
                    alt=""
                    className="w-full h-[300px] lg:w-full lg:h-[400px] object-fit"
                  />
                  <div className=" absolute top-[10rem] lg:top-[15rem] left-10 right-0 bottom-0 flex items-center justify-start opacity-0 group-hover:opacity-100 transition-opacity">
                    <div
                      className={` ${selectedLanguage === "ar"
                        ? "absolute  right-[2rem] lg:right-[1rem] border-r-2 text-direction-arabic "
                        : "absolute left-[2rem] lg:left-[1rem] border-l-2 text-direction-english "
                        }`}
                    >
                      <p className="text-[15px] lg:text-[26px] border-b-2 text-[#FFF] font-[Cairo] font-[600] ps-3 py-3 ">
                        {item.title}
                      </p>
                      <p className="text-[13px] lg:text-[24px] text-[#319AEF] font-[Cairo] font-[400] ps-3 py-3">
                        {item.project_owner}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default SectionThree;
