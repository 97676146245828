import React, { useEffect } from "react";
import { useServices } from "../../Context/ServicesContext";
import { useParams } from 'react-router-dom';

function SectionOne() {
  const { getServices, isServices } = useServices();
  const { id } = useParams();

  useEffect(() => {
    getServices(id);
  }, []);

  return (
    <div className="flex flex-col">
      {isServices?.data?.map((service, index) => (
        <div
          className="flex flex-col items-center lg:flex-row gap-14 my-10"
          key={index}
        >
          {index % 2 ? (
            <>
              <img
                data-aos="fade-left"
                data-aos-delay="1500"
                src={service.image_url}
                alt=""
                className="w-full lg:w-[694px] lg:h-[600px] order-1 lg:order-0"
              />
              <div
                data-aos="fade-left"
                data-aos-delay="1500"
                className="description flex flex-col gap-5 order-0 lg:order-1"
              >
                <h1 className="text-start text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
                  {service.title}
                </h1>
                <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
                  {service.description}
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                data-aos="fade-right"
                data-aos-delay="1500"
                className="description flex flex-col gap-5"
              >
                <h1 className="text-start text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
                  {service.title}
                </h1>
                <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
                  {service.description}
                </p>
              </div>
              <img
                data-aos="fade-right"
                data-aos-delay="1500"
                src={service.image_url}
                alt=""
                className="w-full lg:w-[694px] lg:h-[600px]"
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default SectionOne;
