import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

function SectionTwo({ isProduct }) {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    autoplay: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 500,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col gap-5 w-full ">
      <div className="text-start">
        <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[Cairo] font-[600]">
          {t("RelatedProducts")}
        </h1>
      </div>
      <Slider {...settings}>
        {isProduct?.map((product) => (
          <div key={product.id}>
            <Link to={"/Product/" + product.id}>
              <div className="flex flex-col gap-5 items-start cursor-pointer">
                <img
                  src={product.image_url}
                  alt=""
                  className="w-full h-[350px] lg:w-[300px] lg:h-[300px]"
                />
                <h3 className="text-[#141414] text-[20px] lg:text-[32px] font-[Cairo] font-[400]">
                  {product.title}
                </h3>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SectionTwo;
