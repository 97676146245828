import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrow from "../../Assets/Images/Icon.svg";
import left from "../../Assets/Images/Arrow-l.svg";
import right from "../../Assets/Images/Arrow-r.svg";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { initialLanguage } from "../../Consts"

function SectionThree({ isNewsDetails }) {
  const [sliderRef, setSliderRef] = useState(null);
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState();

  const handlePrevious = () => {
    sliderRef.slickPrev();
  };

  const handleNext = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    autoplay: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 500,
        },
      },
    ],
  };

  return (
    <div className="carousel mx-[2rem]  lg:mx-[7rem]">
      <div className="flex justify-between items-end py-10">
        <div className="relative lg:news-title flex flex-col items-start ">
          <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
            {t("Related Projects")}
          </h1>
        </div>
        <div dir="ltr" className="slide_direction flex  gap-2 justify-end">
          <div className="left" onClick={handlePrevious}>
            <img
              src={left}
              alt=""
              className="w-[10vw] h-[10vw] xl:w-[3vw] xl:h-[3vw] cursor-pointer"
            />
          </div>
          <div className="right" onClick={handleNext}>
            <img
              src={right}
              alt=""
              className="w-[10vw] h-[10vw] xl:w-[3vw] xl:h-[3vw] cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div id="slider" dir="ltr">
        <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
          {isNewsDetails?.map((item, index) => (
            <div key={item.id} className="flex justify-between item-start gap-[2rem] lg:gap-10 overflow-x-auto lg:overflow-hidden overflow-hidden" dir="auto">
              <div
                data-aos="fade-left"
                className="relative group flex flex-col items-start gap-[15px] w-full lg:w-[25vw] "
              >
                <div className="image-container">
                  <Link to={"/news/" + item.id}>
                    <img
                      src={item.image_url}
                      alt=""
                      className="w-full h-[300px] lg:w-full lg:h-[400px] object-fit"
                    />
                  </Link>
                </div>
                <h1
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                  className="text-[13px] lg:text-[20px] text-[#212121] font-[Cairo] font-[600] leading-[24px]"
                >
                  {item.date}
                </h1>
                <p
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                  className="text-start tex-[15px] lg:text-[24px] text-[#212121] font-[Cairo] font-[600] leading-[18px] lg:leading-[30px]"
                >
                  {item.title}
                </p>
                <Link
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                  to={"/news/1"}
                  className="flex gap-1 lg:gap-[0.2rem] items-center text-[#0A66B0] text-[10px] lg:text-[20px] font-[Cairo] font-[600]"
                >
                  {t("readMore")}
                  <img
                    src={arrow}
                    alt=""
                    className={initialLanguage == "ar" ? "flip-horizontal w-[3vw] lg:w-[2vw] lg:h-[1vh]" : "w-[3vw] lg:w-[2vw] lg:h-[1vh]"}
                  // className="w-[3vw] lg:w-[2vw] lg:h-[1vw]"
                  />
                </Link>
              </div>

            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default SectionThree;
