import React, { useEffect, useState } from "react";
import banner from "../../Assets/Images/banner.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Partners({ isHome }) {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);

  const settings = {
    dots: false,
    autoplay: true,
    infinite: isHome.length > 1 ? true : false,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <div className="relative">
      <div 
      className="py-5"
      style={{  
        backgroundImage: `url(${banner})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="lg:banner lg:banner1 flex-col items-center">
          <h1
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
            data-aos-offset="0"
            className="relative text-[#AF1F24] text-[25px] lg:text-[50px] font-[Cairo] font-[400] leading-[120%]"
          >
            {t("OURPARTNERS")}
          </h1>
          <div
            data-aos="fade-left"
            data-aos-delay="2000"
            className=" justify-center lg:flex-row lg:mx-0  gap-[2rem] lg:gap-[2rem] lg:my-5 overflow-x-auto lg:overflow-hidden"
            id="slider"
            dir="ltr"
          >
            <Slider {...settings}>
              {isHome?.map((data, index) => (
                <img
                  key={`partner-${index}`}
                  src={data.image_url}
                  className="w-[150px] h-[150px] lg:w-[210px] lg:h-[150px] 2xl:h-[170px] object-contain"
                  alt={`Partner ${index + 1}`}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
