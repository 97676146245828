import React, { useEffect } from "react";
import Banner from "../Components/About/Banner";
import SectionOne from "../Components/About/SectionOne";
import SectionTwo from "../Components/About/SectionTwo";
import SectionThree from "../Components/About/SectionThree";
import SectionFour from "../Components/About/SectionFour";
import SectionFive from "../Components/About/SectionFive";
import SectionSix from "../Components/About/SectionSix";
import { useAbout } from "../Context/AboutContext";

function AboutUs() {
  const { isAbout, getAbout } = useAbout();

  useEffect(() => {
    getAbout();
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="overlay">
      <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <Banner />
        <SectionOne isAbout={isAbout?.data?.about?.[0]} />
        <SectionTwo isAbout={isAbout?.data?.about?.[1]} />
      </div>
      <SectionThree isAbout={isAbout?.data?.numbers} />
      <div className="flex flex-col lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <SectionFour isAbout={isAbout?.data?.about?.[2]} />
        <SectionFive isAbout={isAbout?.data?.about?.[3]} />
      </div>
      <SectionSix isAbout={isAbout?.data?.about?.[4]} />
    </div>
  );
}

export default AboutUs;
