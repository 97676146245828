import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const storedLanguage = localStorage.getItem('language');
const language = storedLanguage || 'en';

i18n
    // if you want your translations to be loaded from a professional CDN
    .use(Backend)
    // to detect user language
    .use(LanguageDetector)
    // to pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        fallbackLng: 'en',
        backend: {
            loadPath: `/locales/${language}/translation.json`
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        react: {
            useSuspense: false //   <---- this will do the magic
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;