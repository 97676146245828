import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { initialLanguage, newBaseUrl } from "../Consts"

export const ProductsContext = createContext();
export const useProducts = () => {
    return useContext(ProductsContext);
};

export default function ProductsContextProvider({ children }) {
    const [isProducts, setIsProducts] = useState();
    const [isProductsBrands, setIsProductsBrands] = useState();
    const [isProductsSubBrands, setIsProductsSubBrands] = useState();
    const [isProductsCategory, setIsProductsGategory] = useState();
    const [isProduct, setIsProduct] = useState();

    const headers = {
        'Content-Type': 'application/json',
        'Content-Language': initialLanguage
    };

    async function getProducts() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/products`, {headers});
            // console.log(res?.data);
            setIsProducts(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductById(id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/product/${id}`);
            // console.log(res?.data);
            setIsProduct(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsCategory() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/products-category`, {headers});
            // console.log(res?.data?.data);
            setIsProductsGategory(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsBrands() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/products-brands`, {headers});
            setIsProductsBrands(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsSubBrands(id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/products-subbrands/${id}`, {headers});
            setIsProductsSubBrands(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }


    return <ProductsContext.Provider value={{
        getProducts, getProductsCategory, getProductsBrands, getProductsSubBrands, getProductById, getProductsCategory,
        isProducts, isProductsBrands, isProductsSubBrands, isProduct, isProductsCategory
    }}>{children}</ProductsContext.Provider>;
}
