import React from "react";
import News from "./News";

function InfoNews({ isHome }) {

  return (
    <div className="pb-20">
      <main>
        <News isHome={isHome} />
      </main>
    </div>
  );
}

export default InfoNews;
