import React from "react";
import banner from "../../Assets/Images/NUMPER PANNER.png";
import { useTranslation } from "react-i18next";

function SectionThree({ isAbout }) {
  const { t } = useTranslation();

  return (
    // data-aos="fade-right" data-aos-delay="1000"
    <div className="relative w-full my-[9rem]">
      <img
        src={banner}
        alt=""
        className="w-full h-[482px] md:h-[350px] object-cover"
      />
      <div className="absolute inset-0 bg-[#00000080]"></div>
      <div className="absolute inset-0  flex flex-col justify-center items-center gap-10">
        <h1 className="text-[#fff] text-[25px] lg:text-[50px] font-[700] font-[Cairo]">
          {t("numbers")}
        </h1>
        <div className="flex flex-col items-center lg:flex-row md:flex-row gap-[1.5rem] md:gap-[5.5rem] lg:gap-[13rem] ">
          {isAbout?.map((numbers, index) => (
            <div key={index} className="flex items-center flex-col">
              <span className="text-[#fff] text-[25px] lg:text-[50px] font-[700] font-[Cairo]">
                +{numbers.number}
              </span>
              <span className="text-[#fff] text-[20px] lg:text-[36px] font-[600] font-[Cairo]">
                {numbers.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionThree;
