import React from "react";
import about from "../../Assets/Images/Our-Mission.jpg";
import { useTranslation } from "react-i18next";

function SectionFive({ isAbout }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row md:flex-row items-center gap-[3rem] my-5">
      <div data-aos="fade-right" data-aos-delay="1000">
        <img
          src={isAbout?.image_url}
          alt=""
          className="w-[85vw] h-[300px] lg:w-[694px] lg:h-[540px] md:w-[650px] md:h-[480px]"
        />
      </div>
      <div className="flex flex-col w-[85vw] lg:w-[70vw] md:w-[80vw] gap-2">
        <div
          data-aos="fade-left"
          data-aos-delay="1000"
          className="relative lg:services-title flex flex-col items-start "
        >
          <h1 className="text-[#AF1F24] text-[15px] lg:text-[24px] md:text-[14px] font-[400] font-[Cairo]">
            {t("Our Mission")}
          </h1>
          <h1 className="text-start text-[#0A66B0] text-[25px] lg:text-[50px] md:text-[18px] font-[600] font-[Cairo]">
            {t("Reach for the Stars")}
          </h1>
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos-delay="2000"
          className="flex flex-col items-start gap-5"
        >
          <p className="text-start text-[#141414] text-[16px] lg:text-[24px] md:text-[15px] font-[400] font-[Cairo]">
            {isAbout?.excerpt != null ? isAbout?.excerpt : null}
          </p>
          {isAbout?.excerpt != null ? <span className="border"></span> : null}
          {isAbout?.description != null &&
            isAbout?.description.split("\n").map((line, index) => (
              <p
                key={index}
                className="text-start text-[#141414] text-[16px] lg:text-[24px] md:text-[15px] font-[400] font-[Cairo]"
              >
                {line}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
}

export default SectionFive;
