import React, { useEffect } from "react";
import SectionTwo from "../Components/Product/SectionTwo";
import SectionOne from "../Components/Product/SectionOne";
import { useParams } from "react-router-dom";
import { useProducts } from "../Context/ProductsContext";

function Product() {
  const { getProductById, isProduct } = useProducts("");
  const { id } = useParams();

  useEffect(() => {
    getProductById(id);
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [id]);

  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
      <SectionOne isProduct={isProduct?.data?.main_product} />
      <SectionTwo isProduct={isProduct?.data.related_products} />
    </div>
  );
}

export default Product;
