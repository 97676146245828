import React from "react";
import banner from "../../Assets/Images/about-banner.jpeg";
import banner1 from "../../Assets/Images/partners.png";
import { t } from "i18next";

function Banner() {
  return (
    <div className="relative w-full h-[200px] lg:h-[300px] mt-[9rem]">
      <img src={banner} alt="" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-[#00000080]"></div>
      <div
        data-aos="fade-right"
        data-aos-delay="1000"
        className="lg:about-title absolute inset-[2rem] lg:inset-[4rem] flex flex-col justify-center items-start"
      >

        <h1 className="text-[#fff] text-[25px] lg:text-[50px] font-[700] font-[Cairo]">
          {t("Brands")}
        </h1>
      </div>
    </div>
  );
}

export default Banner;
