import axios from "axios";
import { createContext, useContext, useState } from "react";
import { initialLanguage, newBaseUrl } from "../Consts"

export const ProjectsContext = createContext()
export const useProjects = () => {
    return useContext(ProjectsContext)
}

export default function ProjectsContextProvider({ children }) {

    const [isProjects, setIsProjects] = useState()
    const [isProjectsCategories, setIsProjectsCategories] = useState()
    const [isProject, setIsProject] = useState()

    const headers = {
        'Content-Type': 'application/json',
        'Content-Language': initialLanguage
    };
    async function getProjects() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/projects`, { headers });
            // console.log(res?.data?.data);
            setIsProjects(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    async function getProject(id) {
        setIsProject()
        try {
            const res = await axios.get(`${newBaseUrl}/api/project/${id}`, { headers });
            // console.log(res?.data?.data);
            setIsProject(res?.data)
        } catch (err) {
            console.error(err);
        }
    }


    async function getProjectsCategories() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/projects-categories`, { headers });
            setIsProjectsCategories(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return <ProjectsContext.Provider value={{
        getProjects, getProjectsCategories, getProject,
        isProject, isProjects, isProjectsCategories
    }}>
        {children}
    </ProjectsContext.Provider>
}
