import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const AboutContext = createContext();
export const useAbout = () => {
    return useContext(AboutContext);
};

export default function AboutContextProvider({ children }) {
    const [isAbout, setIsAbout] = useState()
    const [isPage, setIsPage] = useState()
    const storedLanguage = localStorage.getItem('language');
    const initialLanguage = storedLanguage || 'en';
    const headers = {
        'Content-Type': 'application/json',
        'Content-Language': initialLanguage
    };
    async function getAbout() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/about`, { headers });
            setIsAbout(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPage(slug) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/page/${slug}`, { headers });
            console.log(res);
            setIsPage(res?.data);
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <AboutContext.Provider value={{
            getAbout, isAbout, getPage, isPage
        }}>
            {children}
        </AboutContext.Provider>
    );
}
