import arrow from "../../Assets/Images/Icon.svg";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNews } from "../../Context/NewsContext";
import { useTranslation } from "react-i18next";
import { initialLanguage } from "../../Consts"

function Cards() {
  const { getNews, isNews, getNewsCategories, isNewsCategories } = useNews();
  const { t } = useTranslation();

  useEffect(() => {
    getNews()
    // getNewsCategories();
  }, []);

  return (
    <div className="flex flex-col gap-12 justify-center w-[100%]">
      {/* <div className="flex flex-col items-start gap-3">
        <label
          htmlFor="category"
          className="text-[#141414] text-[15px] lg:text-[20px] font-[Cairo] font-[600]"
        >
          Year
        </label>
        <select
          id="category"
          className="border-[1px] border-[#DFEAF2] py-1 text-[15px] lg:text-[20px] w-[20rem] px-2"
          style={{ color: "#5F5F5F" }}
        >
          <option>All</option>
          {isNewsCategories?.data?.map((item, index) => (
            <option>{item.name}</option>
          ))}
        </select>
      </div> */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 mt-10">
        {isNews?.data?.map((item, index) => (
          <div
            data-aos="fade-left"
            data-aos-delay="100"
            key={index}
            className="flex flex-col items-start gap-[15px] "
          >
            <div className="image-container">
              <Link to={"/news/" + item.id}>
                {/* w-[480px] */}
                <img src={item.image_url} alt="" className=" w-full h-[370px]" />
              </Link>
            </div>
            <h1
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="100"
              data-aos-offset="0"
              className="text-[13px] lg:text-[15px] text-[#212121] font-[Cairo] font-[600] leading-[24px]"
            >
              {item.date}
            </h1>
            <p
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="100"
              data-aos-offset="0"
              className="text-start text-[16px] lg:text-[20px] text-[#212121] font-[Cairo] font-[600] leading-[30px]"
            >
              {item.title}
            </p>
            <Link
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="100"
              data-aos-offset="0"
              to={"/new"}
              className="flex gap-1 items-center text-[#0A66B0] text-[15px] lg:text-[18px] font-[Cairo] font-[600]"
            >
              {t("readMore")}
              <img
                src={arrow}
                alt=""
                className={initialLanguage == "ar" ? "flip-horizontal w-[3vw] lg:w-[2vw] lg:h-[1vh]" : "w-[3vw] lg:w-[2vw] lg:h-[1vh]"}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
