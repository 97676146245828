import React, { useEffect } from "react";
import SectionOne from "../Components/Project/SectionOne";
import SectionTwo from "../Components/Project/SectionTwo";
import SectionThree from "../Components/Project/SectionThree";
import { useProjects } from "../Context/ProjectsContext";
import { useParams } from "react-router-dom";

function Project() {
  const { getProject, isProject } = useProjects("");
  const { id } = useParams();

  useEffect(() => {
    getProject(id);
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [id]);

  return (
    <div className="overlay">
      <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <SectionOne isProject={isProject?.data?.main_project} />
        <SectionTwo isProject={isProject?.data.main_project.description} />
      </div>
      <SectionThree isProject={isProject?.data.related_projects} />
    </div>
  );
}

export default Project;
