import React from "react";
import Carousel from "./Carousel";

function Img({ isHome }) {
  const images = isHome.map((image, index) => ({
    number: index + 1,
    formattedNumber: index + 1 < 10 ? `0${index + 1}` : `${index + 1}`,
    data: image,
  }));
  return (
    <div>
      <main>
        <Carousel isHome={images} />
      </main>
    </div>
  );
}

export default Img;
