import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useServices } from "../../Context/ServicesContext";

function Cards() {
  const [hoveredService, setHoveredService] = useState(null);
  const { getServicesCategory, isServicesCategory } = useServices();

  useEffect(() => {
    getServicesCategory();
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);

  const handleMouseEnter = (serviceId) => {
    setHoveredService(serviceId);
  };

  const handleMouseLeave = () => {
    setHoveredService(null);
  };

  const handleTouchStart = (serviceId) => {
    // Simulate hover effect on touch devices
    setHoveredService(serviceId);
  };

  const handleTouchEnd = () => {
    // Remove hover effect on touch devices
    setHoveredService(null);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-10 w-[100%] mt-10">
      {isServicesCategory?.data?.map((service) => (
        <Link
          data-aos="fade-left"
          data-aos-delay="1000"
          key={service.id}
          to={"/service/" + service.id}
          onTouchStart={() => handleTouchStart(service.id)}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="relative group cursor-pointer"
            onMouseEnter={() => handleMouseEnter(service.id)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={service.image_url}
              alt=""
              className="lg:w-[50vh] lg:h-[40vh]"
            />

            <div
              className={`absolute inset-x-0 bottom-0 lg:h-[10vh] h-[10vh] md:h-[6vh] grid place-items-center bg-[#00000080] ${
                hoveredService === service.id ? "opacity-0" : "opacity-100"
              } transition-opacity`}
            >
              <h1 className="text-white text-[20px] lg:text-[30px] font-[600] font-[Cairo]">
                {service.name}
              </h1>
            </div>

            <div
              className={`absolute inset-0 grid place-items-center bg-[#00000080]  ${
                hoveredService === service.id ? "opacity-100" : "opacity-0"
              } transition-opacity`}
            >
              <h1
                data-aos="fade-up"
                data-aos-duration="3000"
                className="text-center text-white text-[15px] lg:text-[20px] font-[600] font-[Cairo] w-[35vh] h-[35vh] sm:w-[70vw] sm:h-[50vw] md:w-[37vw] md:h-[15vh] lg:w-[35vh] lg:h-[35vh] xl:w-[35vh] xl:h-[35vh] 2xl:w-[35vh] 2xl:h-[35vh] p-2 border-2 border-[#0966AE] flex items-center justify-center"
              >
                {service.excerpt}
              </h1>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Cards;
