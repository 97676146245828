import React, { useRef } from "react";
import contact from "../../Assets/Images/contact.jpg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { newBaseUrl } from "../../Consts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Form() {
  const { t } = useTranslation();
  const formRef = useRef(null); // Reference to the form element

  const handleSendClick = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      const body = {
        'name': document.getElementById('name').value,
        'email': document.getElementById('email').value,
        'phone': document.getElementById('phone').value,
        'subject': document.getElementById('subject').value,
        'content': document.getElementById('content').value,
      };

      const response = await axios.post(`${newBaseUrl}/api/contact`, body);
      toast.success(response.data.content)
      formRef.current.reset();
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message)
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center gap-5 lg:gap-36 justify-between lg:pe-0 lg:ps-20 mt-16 mb-10 w-full">
      <form ref={formRef} className="lg:w-[40vw]">
        <h2 style={{ whiteSpace: "pre-line" }} className="flex flex-col lg:flex-row items-center text-start text-[42px] pb-5 font-[Cairo] font-[600]">
          {"Should you have any questions, <br/> please feel free to contact us.".split("<br/>").join("\n")}</h2>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Name")}
          </label>
          <input
            type="text"
            id="name"
            placeholder={t("Enter Your Name")}
            className="border-[1px] h-[40px] w-full lg:w-full lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Email")}
          </label>
          <input
            type="text"
            id="email"
            placeholder={t("Enter Your Email")}
            className="border-[1px]  h-[40px] w-full lg:w-full lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Phone")}
          </label>
          <input
            type="text"
            id="phone"
            placeholder={t("EnterYourPhone")}
            className="border-[1px]  h-[40px] w-full lg:w-full lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Subject")}
          </label>
          <input
            type="text"
            id="subject"
            placeholder={t("EnterYourSubject")}
            className="border-[1px]  h-[40px] w-full lg:w-full lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Message")}
          </label>
          <textarea
            placeholder={t("EnterYourMessage")}
            id="content"
            className="border-[1px] w-full lg:w-full h-[140px] px-5 py-3"
          />
        </div>
        <button onClick={handleSendClick} className="bg-[#0A66B0] text-[#FFF] text-center w-full text-[18px] lg:text-[20px] 
        font-[Cairo] font-[600] py-3 mt-10 border-[2px] border-[#0A66B0] hover:bg-[#fff] hover:text-[#0A66B0]">
          {t("Send")}
        </button>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
      </form>
      <img src={contact} alt="" className="h-[50vh] lg:w-[50vw] lg:h-[100vh] w-full" />
    </div>
  );
}

export default Form;
