import axios from "axios";
import { createContext, useContext, useState } from "react";
import { initialLanguage, newBaseUrl } from "../Consts"

export const ServicesContext = createContext();
export const useServices = () => {
    return useContext(ServicesContext);
};

export default function ServicesContextProvider({ children }) {
    const [isServices, setIsServices] = useState()
    const [isServicesCategory, setIsServicesCategory] = useState()
    const headers = {
        'Content-Type': 'application/json',
        'Content-Language': initialLanguage
    };
    async function getServicesCategory() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/services-category`, { headers });
            setIsServicesCategory(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getServices(id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/services/${id}`, { headers });
            setIsServices(res?.data);
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <ServicesContext.Provider value={{ getServicesCategory, getServices, setIsServicesCategory, isServicesCategory, setIsServices, isServices }}>
            {children}
        </ServicesContext.Provider>
    );
}
