import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHome } from "../../Context/HomeContext";
import fb from "../../Assets/Images/Frame.svg";
import ig from "../../Assets/Images/Frame (1).svg";
import li from "../../Assets/Images/Frame (2).svg";
import yt from "../../Assets/Images/Frame (3).svg";
import wu from "../../Assets/Images/Frame (4).svg";

function Footer() {
  const { isSocial, getSocial } = useHome();
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [images, setImages] = useState([]);

  useEffect(() => {
    getSocial('arch')
      .then(socialData => {
        // Merge the images array into the isSocial data
        const updatedIsSocial = { ...socialData, images: [fb, ig, li, yt, wu] };
        setImages(updatedIsSocial.images);
      })
      .catch(error => {
        console.error("Error fetching social data:", error);
      });
  }, []);

  return (
    <div className="px-[2rem] lg:px-[6rem] py-16 flex flex-col justify-center">
      <div className="border-t-[1px] border-[#0A66B0] py-5 flex flex-col items-center justify-between lg:flex-wrap lg:justify-around gap-5 lg:gap-0 lg:flex-row md:flex-row  text-[#0A66B0] text-[15px] lg:text-[18px] font-[Cairo] font-[700] leading-[120%] lg:border-0">
        <div className="flex flex-col lg:flex-row gap-[10px] lg:gap-[19px] lg:ms-[2rem] justify-between w-full">
          <Link to={'/careers'}><h1>{t("Careers")}</h1></Link>
          <Link to={'/contact'}><h1>{t("Contact Us")}</h1></Link>
          <div className="flex flex-row gap-[10px] lg:gap-[19px] lg:ms-[6rem] justify-center">
            {images.map((image, index) => (
              <Link key={index} to={isSocial?.data[index]?.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={image}
                  alt=""
                  className="w-[1.3rem] h-[1.3rem]"
                />
              </Link>
            ))}
          </div>
          <Link to={'/Terms&Conditions'}><h1>{t("Terms & Conditions")}</h1></Link>
          <Link to={'/PrivacyPolicy'}><h1>{t("Privacy Policy")}</h1></Link>
        </div>
      </div>
      <div className="text-[#0A66B0] text-[10px] lg:text-[16px] font-[Cairo] font-[600] leading-[120%] justify-between mt-5">
        <h3>
          © {currentYear} {t("Arch Sense Group. All right reserved.")}
          <br /> Designed by <span className="underline"><Link to={'https://codescreed.com'} target="_blank">Codescreed</Link></span>
        </h3>
      </div>
    </div>
  );


}

export default Footer;
