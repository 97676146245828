import React, { useEffect } from "react";
import Banner from "../Components/AllProducts/Banner";
import Cards from "../Components/AllProducts/Cards";

function AllProducts() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
      <Banner />
      <Cards />
    </div>
  );
}

export default AllProducts;
