import React, { useEffect } from "react";
import about from "../../Assets/Images/about.jpeg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function AboutSection({ isHome }) {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between md:flex-row mx-[2rem] md:mx-[4rem] 2xl:mx-[7rem] xl:mx-[7rem] lg:mx-[7rem] items-center gap-5 my-5">
      <div className="flex flex-col w-[85vw] lg:w-[51vw] gap-2">
        <div
          data-aos="fade-right"
          className="relative lg:services-title flex flex-col items-start "
        >
          <h1 className="text-[#AF1F24] text-[15px] lg:text-[24px] font-[400] font-[Cairo]">
            {t("WHO WE ARE")}
          </h1>
          <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
            {t("About")}
          </h1>
        </div>
        <div
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0"
          className="flex flex-col items-start gap-5"
        >
          <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
            {isHome?.excerpt != null ? isHome?.excerpt : null}
          </p>
          {isHome?.excerpt != null ? <span className="border"></span> : null}
          <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
            {isHome?.description}
          </p>
        </div>
        <Link data-aos="fade-right" className="me-auto" to={"/about"}>
          <button className="mt-5 py-2 px-6 rounded-full border-[1px] border-[#0A66B0] text-[#0A66B0] text-[13px] lg:text-[20px] font-[400] font-[Cairo]">
            {t("ViewMore")}
          </button>
        </Link>
      </div>
      <div data-aos="fade-left">
        <img src={isHome?.image_url} alt="" className="w-[694px]" />
      </div>
    </div>
  );
}

export default AboutSection;
