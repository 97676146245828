import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "aos/dist/aos.css";
import Aos from 'aos';
import './i18next';
import lodear from "./Assets/Images/loder.gif"
import Home from './Pages/Home';

Aos.init({
  duration: 1000,
  once: true,
  easing: "ease",
});

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <React.StrictMode>

      {isLoading ? (
        <div className="loader-container">
          <img src={lodear} className='loader-image' />
        </div>
      ) : (
        <App />)}

    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);

reportWebVitals();
