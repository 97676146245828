import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

function DetailsCareer({ isCareerById }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col items-start gap-3">
        <h1
          data-aos="fade-right"
          data-aos-delay="1400"
          className="text-[#0A66B0] text-[18px] lg:text-[36px] font-[Cairo] font-[600]"
        >
          {t("JobDescription")}
        </h1>

        {isCareerById?.data?.details.split("\n").map((line, index) => (
          <p
            data-aos="fade-right"
            data-aos-delay="1600"
            className="text-start text-[#141414] text-[14px] lg:text-[28px] font-[Cairo] font-[400]"
          >
            {line}
          </p>
        ))}
      </div>
      <div className="flex flex-col items-start gap-3">
        <h1
          data-aos="fade-right"
          data-aos-delay="1400"
          className="text-[#0A66B0] text-[18px] lg:text-[36px] font-[Cairo] font-[600]"
        >
          {t("Skills")}
        </h1>
        {isCareerById?.data?.skills.split("\n").map((line, index) => (
          <p
            data-aos="fade-right"
            data-aos-delay="1600"
            className="text-start text-[#141414] text-[14px] lg:text-[28px] font-[Cairo] font-[400]"
          >
            {line}
          </p>
        ))}
      </div>
      <div className="flex flex-col items-start gap-3">
        <h1
          data-aos="fade-right"
          data-aos-delay="1400"
          className="text-[#0A66B0] text-[18px] lg:text-[36px] font-[Cairo] font-[600]"
        >
          {t("Education")}
        </h1>
        {isCareerById?.data?.education.split("\n").map((line, index) => (
          <p
            data-aos="fade-right"
            data-aos-delay="1600"
            className="text-start text-[#141414] text-[14px] lg:text-[28px] font-[Cairo] font-[400]"
          >
            {line}
          </p>
        ))}
      </div>
      <Link
        data-aos="fade-right"
        data-aos-delay="1700"
        to={"/applyNow/" + isCareerById?.data?.title}
      >
        <button className="bg-[#0A66B0] text-[#FFF] text-[15px] lg:text-[20px] font-[Cairo] font-[400] block me-auto mt-14 mb-5 py-2 px-8 rounded-full">
          {t("ApplyNow")}
        </button>
      </Link>
    </div>
  );
}

export default DetailsCareer;
