import React from "react";

function SectionTwo({ isProject }) {
  return (
    <div className="flex flex-col w-full">
      {isProject?.map((projectDescription, index) => (
        <div
          className="flex flex-col items-center justify-between lg:flex-row gap-14 my-10"
          key={index}
        >
          {index % 2 ? (
            <>
              <img
                data-aos="fade-down"
                data-aos-delay="200"
                src={projectDescription.image_url}
                alt=""
                className="w-full lg:w-[694px] lg:h-[600px]"
              />
              <div
                data-aos="fade-right"
                data-aos-delay="200"
                className="description flex flex-col gap-5 "
              >
                {projectDescription.short_description != null &&
                  projectDescription.short_description.split("\n").map((line, index) => (
                    <h1 className="text-start text-[#141414] text-[16px] lg:text-[22px] font-[400] font-[Cairo]">
                      {projectDescription.short_description}
                    </h1>
                  ))}
                {projectDescription.short_description != null ? <span className="border"></span> : null}
                <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
                  {projectDescription.description}
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                data-aos="fade-left"
                data-aos-delay="200"
                className="description flex flex-col gap-5 order-1 lg:order-0"
              >
                {projectDescription.short_description != null &&
                  projectDescription.short_description.split("\n").map((line, index) => (
                    <h1 className="text-start text-[#141414] text-[16px] lg:text-[22px] font-[400] font-[Cairo]">
                      {projectDescription.short_description}
                    </h1>
                  ))}
                {projectDescription.short_description != null ? <span className="border"></span> : null}
                <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
                  {projectDescription.description}
                </p>
              </div>
              <img
                data-aos="fade-down"
                data-aos-delay="200"
                src={projectDescription.image_url}
                alt=""
                className="w-full lg:w-[694px] lg:h-[600px] order-0 lg:order-1"
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default SectionTwo;
