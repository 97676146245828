import React from "react";
import about from "../../Assets/Images/about6.jpg";
import se from "../../Assets/Images/sec6.jpg";
import { useTranslation } from "react-i18next";

function SectionSix({ isAbout }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row md:flex-row  items-center  mt-[6.5rem]  mb-5">
      <div className="relative w-[100%] h-[100%] lg:w-[70%] lg:h-[565px]">
        <img src={se} alt="" className="w-full h-[740px] lg:h-[565px]" />
        <div className="absolute inset-0 bg-[#00000080]"></div>
        {/* w-full */}
        {/* lg:m-[4rem]*/}
        <div className="flex flex-col justify-center  absolute top-1/2 transform -translate-y-1/2 gap-2 px-7">
          <div className="relative lg:services-title flex flex-col items-start text-start">
            <h1 className="text-[#AF1F24] text-[15px] md:text-[14px] lg:text-[24px] font-[Cairo]">
              {t("FOUNDER")}
            </h1>
            <h1 className="text-start text-[#0A66B0] text-[25px] md:text-[18px] lg:text-[50px] font-[600] font-[Cairo]">
              {t("Amr Saleh")}
            </h1>
          </div>
          <div className="flex flex-col items-start gap-5 text-start ">
            <p className="text-start text-[#FFF] text-[16px] md:text-[15px] lg:text-[22px] font-[400] font-[Cairo]">
              {isAbout?.excerpt != null ? isAbout?.excerpt : null}
            </p>
            {isAbout?.excerpt != null ? <span className="border"></span> : null}
            {isAbout?.description != null &&
              isAbout?.description.split("\n").map((line, index) => (
                <p
                  key={index}
                  className="text-start text-[#FFF] text-[16px] md:text-[15px] lg:text-[22px] font-[400] font-[Cairo]"
                >
                  {line}
                </p>
              ))}
          </div>
        </div>
      </div>

      <div className="w-[100%] h-[100%] lg:w-[30%] lg:h-[565px]">
        <img
          src={about}
          alt=""
          className="w-[100%] h-[100%] lg:w-[100%] lg:h-[565px] md:w-[100%] md:h-[740px]"
        />
      </div>
    </div>
  );
}

export default SectionSix;
