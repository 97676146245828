import React, { useEffect } from "react";
import Services from "./Services";
import Cards from "../../Services/Cards";
import Aos from "aos";
import "aos/dist/aos.css";

function InfoServices() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);
  return (
    <div className="flex flex-col justify-center mx-[2rem] md:mx-[4rem] lg:mx-[7rem] xl:mx-[7rem] 2xl:mx-[7rem] pb-20 my-5">
      <Services />
      <Cards />
    </div>
  );
}

export default InfoServices;
