// CareersContextProvider.js

import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { initialLanguage, newBaseUrl } from "../Consts";

export const CareersContext = createContext();
export const useCareers = () => {
    return useContext(CareersContext);
};

export default function CareersContextProvider({ children }) {
    const [isCareers, setIsCareers] = useState([]);
    const [isCareerById, setIsCareerById] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        'Content-Language': initialLanguage
    };
    async function getCareers() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/careers`, { headers });
            // console.log(res.data);
            setIsCareers(res?.data);
        } catch (err) {
            console.error(err);
        }
    }
    async function getCareerById(id) {
        // console.log(id);
        try {
            const res = await axios.get(`${newBaseUrl}/api/career/${id}`);
            // console.log(res.data);
            setIsCareerById(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <CareersContext.Provider value={{ isCareers, getCareers, getCareerById, isCareerById }}>
            {children}
        </CareersContext.Provider>
    );
}
