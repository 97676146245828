import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SectionOne({ isProject }) {
  const [mainImage, setMainImage] = useState("");
  const [sliderOpen, setSliderOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isProject) {
      if (isProject.gallery && isProject.gallery.length > 0) {
        setMainImage(isProject.gallery[0].image_url);
        setGalleryImages(isProject.gallery);
      } else {
        setMainImage(isProject.image_url);
        setGalleryImages([]);
      }
    }
  }, [isProject]);

  const handleThumbnailClick = (index) => {
    setMainImage(index);
  };

  const handleCloseSlider = () => {
    setSliderOpen(false);
  };

  const handleNextImage = () => {
    if (selectedImageIndex < galleryImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row mt-[8rem] lg:mt-[10rem] items-center gap-10 lg:gap-10 w-full">
      <div data-aos="fade-in"
        data-aos-delay="500"
        className="flex flex-col lg:w-[50vw] lg:flex-row-reverse justify-end items-center gap-7">
        <img
          src={mainImage}
          alt=""
          style={{ maxHeight: "800px" }}
          className={isProject?.gallery && isProject?.gallery.length > 0 ? "w-full lg:w-[33vw] lg:h-[45vh] cursor-pointer object-cover" :
            "w-full lg:w-full cursor-pointer"}
          onClick={() => setSliderOpen(true)}
        />
        {isProject?.gallery && isProject?.gallery.length > 0 ?
          <div className="flex lg:flex-col items-center gap-5 lg:h-[45vh] " style={{ overflow: 'auto' }}>
            {isProject?.gallery.map((item, index) => (
              <img
                key={index}
                src={item.image_url}
                alt=""
                className="w-[20vw] h-[20vw] lg:w-[8vw] lg:h-[5vw] cursor-pointer object-cover"
                onClick={() => handleThumbnailClick(item.image_url, index)}
              />
            ))}
          </div> : null}
      </div>
      <div data-aos="fade-left" data-aos-delay="500" className="flex flex-col text-start gap-[1rem] lg:gap-6 lg:w-[50vw]">
        <h1 className="text-[#0A66B0] text-[20px] lg:text-[40px] font-[Cairo] font-[700]">{isProject?.title}</h1>
        <p className="text-[#141414] text-[16px] lg:text-[24px] font-[Cairo] font-[400]">{isProject?.excerpt}</p>
        <span className="border"></span>
        <p className="text-[#141414] text-[16px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">{t("projectOwner")}</span>{isProject?.project_owner}
        </p>
        <p className="text-[#141414] text-[16px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">{t("area")}</span>{isProject?.area}
        </p>
        <p className="text-[#141414] text-[16px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">{t("year")}</span>{isProject?.year}
        </p>
      </div>

      {sliderOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center">
          <div className="relative">
            <span
              className="absolute top-2 right-2 text-white cursor-pointer"
              onClick={handleCloseSlider}
            >
              &#10005;
            </span>
            {selectedImageIndex > 0 && (
              <span
                className="absolute top-1/2 transform -translate-y-1/2 left-2 text-white cursor-pointer"
                onClick={handlePrevImage}
              >
                &#10094;
              </span>
            )}
            {selectedImageIndex < galleryImages.length - 1 && (
              <span
                className="absolute top-1/2 transform -translate-y-1/2 right-2 text-white cursor-pointer"
                onClick={handleNextImage}
              >
                &#10095;
              </span>
            )}
            <img
              src={isProject?.gallery && isProject?.gallery.length > 0 ? galleryImages[selectedImageIndex].image_url : mainImage}
              alt=""
              className="max-h-[90vh] mx-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SectionOne;
