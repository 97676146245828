import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCareers } from "../../Context/CareersContext";

function Cards() {
  const { isCareers, getCareers } = useCareers();
  // console.log("hello", isCareers);
  const { t } = useTranslation();
  useEffect(() => {
    getCareers();
  }, []);
  return (
    <div className="flex flex-col gap-12 my-10 justify-center w-[100%]">
      {/* <div className="flex flex-col items-start gap-3">
        <label
          htmlFor="category"
          className="text-[#141414] text-[15px] lg:text-[20px] font-[Cairo] font-[600]"
        >
          {t("Specialization")}
        </label>
        <select
          id="category"
          className="border-[1px] border-[#DFEAF2] py-1 text-[15px] lg:text-[20px] w-[20rem] px-2"
          style={{ color: "#5F5F5F" }}
        >
          <option>{t("allSpecialization")}</option>
          <option>All Specialization</option>
          <option>All Specialization</option>
        </select>
      </div> */}
      <div className="flex flex-col gap-14">
        {isCareers?.data?.map((job) => (
          <div
            key={job.id}
            data-aos="fade-left"
            data-aos-delay="1000"
            className="flex flex-col justify-center items-center lg:flex-row px-14 py-8 lg:gap-10 shadow-custom border-r-[10px] border-[#0A66B0]"
          >
            <div className="flex flex-col items-start gap-5 w-[60vw]">
              <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
                {job.title}
              </h1>
              <div className="flex gap-3 lg:gap-8">
                <h3 className="text-[#AF1F24] text-[13px] text-start lg:text-[20px] font-[400] font-[Cairo]">
                  Job Location: {job.location}
                </h3>
                <h3 className="text-[#AF1F24] text-[13px] text-start lg:text-[20px] font-[400] font-[Cairo]">
                  Job Position: {job.position}
                </h3>
                <h3 className="text-[#AF1F24] text-[13px] text-start lg:text-[20px] font-[400] font-[Cairo]">
                  Employment Status: {job.emp_status}
                </h3>
              </div>
              <p className="text-start text-[#141414] text-[15px] lg:text-[20px] font-[Cairo] font-[400] w-[51vw]">
                {job.excerpt}
              </p>
            </div>
            <Link to={"/career/" + job.id}>
              <button className="mt-5 me-20 py-2 px-6 rounded-full border-[2px] border-[#0A66B0] text-[#0A66B0] text-[13px] lg:text-[15px] font-[400] font-[Cairo]">
                {t("ViewDetails")}
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
