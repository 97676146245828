import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function SectionOne({ isAbout }) {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);
  return (
    // gap-[6.25rem]
    <div className="flex flex-col lg:flex-row md:flex-row items-center gap-[3rem] my-5 ">
      <div
        data-aos="fade-right"
        data-aos-delay="2000"
        className="flex flex-col items-start order-1 lg:order-0 lg:w-[53vw] md:w-[80vw] "
      >
        {/* lg:w-[53vw] */}
        <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
          {isAbout?.excerpt != null ? isAbout?.excerpt : null}
        </p>
        {isAbout?.excerpt != null ? <span className="border"></span> : null}
        {isAbout?.description != null &&
          isAbout?.description.split("\n").map((line, index) => (
            <div key={index}>
              <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo] leading-[2rem]">
                {line}
              </p>
            </div>
          ))}
      </div>
      <div
        data-aos="fade-down"
        data-aos-delay="1000"
        className="order-0 lg:order-1"
      >
        <img
          src={isAbout?.image_url}
          alt=""
          className="w-[85vw] lg:w-[694px] lg:h-[540px] md:w-[650px] md:h-[480px]"
        />
      </div>
    </div>
  );
}

export default SectionOne;
