import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useAbout } from "../../Context/AboutContext";
import { useTranslation } from "react-i18next";

function SectionOne() {
  const { getPage, isPage } = useAbout();

  useEffect(() => {
    getPage("privacy")
    // getNewsCategories();
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);

  
  return (
    // gap-[6.25rem]
<div className="flex flex-col lg:flex-row md:flex-row items-start gap-[3rem] my-5 ">
  <div
    data-aos="fade-right"
    data-aos-delay="2000"
    className="flex flex-col items-start"
    dir={isPage?.data?.lang_id === 1 ? "ltr" : "rtl"}
    style={{ textAlign: 'start' }}
  >
    <div dangerouslySetInnerHTML={{ __html: isPage?.data.content }} />
  </div>
</div>

  );
}

export default SectionOne;
