import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useProjects } from "../../Context/ProjectsContext";
import { useTranslation } from "react-i18next";

function Cards() {
  const { t } = useTranslation();

  const {
    getProjects,
    isProjects,
    getProjectsCategories,
    isProjectsCategories,
  } = useProjects();
  const [selectedOption, setSelectedOption] = useState("All");
  const [selectedLanguage, setSelectedLanguage] = useState();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setSelectedOption("All");
    getProjects();
    getProjectsCategories();
    toggleLanguage();
  }, []);

  const toggleLanguage = () => {
    const newLanguage = localStorage.getItem("language");
    setSelectedLanguage(newLanguage);
  };

  return (
    <div className="flex flex-col gap-12 my-10 justify-center items-center w-[100%]">
      <div className="flex gap-[0.8rem] lg:gap-10">
        <button
          onClick={() => handleOptionChange("All")}
          className={
            selectedOption === "All"
              ? "active text-[#0A66B0] text-[18px] lg:text-[20px] font-[600] font-[Cairo]"
              : "text-[18px] lg:text-[20px] font-[600] font-[Cairo]"
          }
          key="All"
        >
          {t("all")}
        </button>
        {isProjectsCategories?.data?.map((category) => (
          <button
            onClick={() => handleOptionChange(category.id)}
            className={
              selectedOption === category.id
                ? "active text-[#0A66B0] text-[18px] lg:text-[20px] font-[600] font-[Cairo]"
                : "text-[18px] lg:text-[20px] font-[600] font-[Cairo]"
            }
            key={category.id}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
        {isProjects?.data?.map(
          (item, index) =>
            (selectedOption === "All" ||
              item.project_cat_id === selectedOption) && (
              <Link
                data-aos="zoom-in"
                data-aos-delay="100"
                to={"/project/" + item.id}
                key={index}
              >
                <div className="relative group flex flex-col items-start gap-[15px] hover-overlay">
                  <img
                    src={item.image_url}
                    alt=""
                    className="w-full h-[295px] object-cover"
                  />
                  <div className=" absolute top-[10rem] lg:top-[9rem] left-0 right-0 bottom-0 flex items-center justify-start opacity-0 group-hover:opacity-100 transition-opacity">
                    <div
                      className={` ${
                        selectedLanguage === "ar"
                          ? "absolute  right-[2rem] lg:right-[1rem] border-r-2 text-direction-arabic "
                          : "absolute left-[2rem] lg:left-[1rem] border-l-2 text-direction-english "
                      }`}
                    >
                      <p className="text-start text-[15px] lg:text-[15px] border-b-2 text-[#FFF] font-[Cairo] font-[600] ps-3 py-3 ">
                        {item.title}
                      </p>
                      <p className="text-start text-[13px] lg:text-[15px] text-[#319AEF] font-[Cairo] font-[400] ps-3 py-3">
                        {item.project_owner}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
}

export default Cards;
