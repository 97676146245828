import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-[70vh]">
      <div className="text-center">
        <h1 className="text-3xl lg:text-5xl font-bold mb-4">Page Not Found</h1>
        <Link to="/home" replace>

          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Go To Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
