import React from "react";
import Projects from "./Projects";

function InfoProject({ isHome }) {

  return (
    <div className="bg-[#F1F1F180] pb-20">
      <main>
        <Projects isHome={isHome} />
      </main>
    </div>
  );
}

export default InfoProject;
