import React from "react";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();

  return (
    <div
      data-aos="fade-right"
      data-aos-delay="1000"
      className="relative lg:services-title flex flex-col items-start pt-[100px]"
    >
      <h1 className="text-[#AF1F24] text-[15px] lg:text-[24px] font-[400] font-[Cairo]">
      {t("WHAT WE DO")}
      </h1>
      <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[600] font-[Cairo]">
      {t("Our Services")}
      </h1>
    </div>
  );
}

export default Services;
