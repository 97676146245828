import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function SectionTwo({ isAbout }) {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);

  return (
    <div className="flex flex-col lg:flex-row md:flex-row items-center gap-[4rem] my-5 ">
      <div data-aos="fade-up" data-aos-delay="1000">
        <img
          src={isAbout?.image_url}
          alt=""
          className="w-[700px] h-[300px] lg:w-[694px] lg:h-[400px] md:w-[650px] md:h-[480px]"
        />
      </div>
      <div
        data-aos="fade-left"
        data-aos-delay="2000"
        className="flex flex-col items-start lg:w-[70vw] md:w-[80vw]"
      >
        {/* lg:w-[70vw] */}
        <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
          {isAbout?.excerpt != null ? isAbout?.excerpt : null}
        </p>
        {isAbout?.excerpt != null ? <span className="border"></span> : null}
        {isAbout?.description != null &&
          isAbout?.description.split("\n").map((line, index) => (
            <div key={index}>
              <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]">
                {line}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SectionTwo;
