import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import arrowl from "../../../Assets/Images/Arrowlh.svg";
import arrowr from "../../../Assets/Images/Arrowrh.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel({ isHome }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === isHome.length ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? isHome.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + 1 === isHome.length ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(slideInterval);
  });
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);

  return (
    <div className="carousel relative">
      <div className="image-overlay w-full h-[60vh] lg:w-full lg:h-[100vh] absolute top-0 left-0 bg-[#00000040] "></div>
      <div
        className="lg:w-[36vw] md:w-[67vw] md:inset-auto absolute my-[10rem] left-0 text-[#fff] mx-[1rem]
       lg:inset-x-auto lg:inset-y-auto lg:right-auto lg:mt-[15rem] lg:mb-[13rem] lg:me-auto lg:ms-[7rem] 
      md:left-[0rem] md:right-auto md:mt-[25rem] md:mb-[25rem] md:me-auto"
      >
        <h1
          data-aos="fade-left"
          className="text-start lg:text-start text-[30px] lg:text-[80px] md:text-[80px] text-[#fff] font-[Cairo] lg:leading-[90px] font-[700] pb-4"
        >
          {isHome[currentIndex]?.data?.title}
        </h1>
        <p
          data-aos="fade-left"
          data-aos-delay="2000"
          className="text-start text-[20px] lg:text-start lg:text-[32px] text-[#fff] font-[Cairo] font-[400] lg:leading-[40px]"
        >
          {isHome[currentIndex]?.data?.excerpt}
        </p>
      </div>

      <img
        src={isHome[currentIndex]?.data?.image_url}
        className="w-full h-[60vh] lg:h-[100vh]"
        alt=""
      />
      <div className="absolute bottom-0 right-[2rem] lg:right-[7rem] left-auto ">
        <div
          data-aos="fade-left"
          data-aos-delay="100"
          className="slide_direction flex gap-7 items-end"
        >
          <div dir="ltr" className="flex justify-center gap-5 pb-2 lg:pb-3">
            <div className="left cursor-pointer" onClick={handlePrevious}>
              <img src={arrowl} alt="" className="w-[35px] lg:w-[50px]" />
            </div>
            <div className="right cursor-pointer" onClick={handleNext}>
              <img src={arrowr} alt="" className="w-[35px] lg:w-[50px]" />
            </div>
          </div>
          <h4 className="text-[30px] lg:text-[50px] text-[#fff] font-[700] font-[Cairo] pb-[7px] lg:pb-1 m-0">
            {isHome[currentIndex]?.formattedNumber}
          </h4>
          <div className="image-number "></div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
