import React, { useEffect } from "react";
import arrow from "../../Assets/Images/arrow.svg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useProducts } from "../../Context/ProductsContext";
import { useTranslation } from "react-i18next";

function SectionOne() {
  const { t } = useTranslation();

  const { getProducts, isProducts } = useProducts();

  useEffect(() => {
    getProducts();
  }, []);

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 500,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 500,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col gap-5 my-10 w-full ">
      <div className="flex justify-between items-center">
        <h1 className="text-[#0A66B0] text-[22px] lg:text-[44px] font-[Cairo] font-[600]">
          {t("Products")}
        </h1>
        <Link to={"/allProducts"}>
          <h3 className="text-[#AAAAAA] text-[13px] lg:text-[18px] font-[Cairo] font-[600] flex items-center gap-2">
            {t("View All")}

            <img src={arrow} alt="" className="w-[15px] lg:w-[25px]" />
          </h3>
        </Link>
      </div>
      <Slider {...settings}>
        {isProducts?.data?.map((product) => (
          <div key={product.id} data-aos="fade-up" data-aos-delay="1000">
            <Link to={"/Product/" + product.id}>
              <div className="flex flex-col gap-5 items-start cursor-pointer">
                <img
                  src={product.image_url}
                  alt=""
                  className="w-full h-[350px] lg:w-[300px] lg:h-[300px]"
                />
                <h3 className="text-[#141414] text-[20px] lg:text-[32px] font-[Cairo] font-[400]">
                  {product.title}
                </h3>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SectionOne;
