import React from "react";


function SectionTwo({ isNewsDetails }) {

  return (
    <div className="flex flex-col">
      {isNewsDetails?.map((post, index) => (
        <div
          className="flex flex-col items-center lg:flex-row gap-14 my-10"
          key={index}
        >
          {index % 2 ? (
            <>
              <img
                data-aos="fade-down"
                data-aos-delay="200"
                src={post.image_url}
                alt=""
                className="w-full lg:w-[694px] lg:h-[600px]"
              />
              <div
                data-aos="fade-right"
                data-aos-delay="200"
                className="description flex flex-col gap-5"
              >
                <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]" style={{ textAlign: "justify" }}>
                  {post.content}
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                data-aos="fade-left"
                data-aos-delay="200"
                className="description flex flex-col gap-5 order-1 lg:order-0"
              >
                <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400] font-[Cairo]" style={{ textAlign: "justify" }}>
                  {post.content}
                </p>
              </div>
              <img
                src={post.image_url}
                alt=""
                data-aos="fade-down"
                data-aos-delay="200"
                className="w-full lg:w-[694px] lg:h-[600px] order-0 lg:order-1"
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default SectionTwo;
