import React, { useEffect } from "react";
import banner from "../../Assets/Images/fitout-banner.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function LatestProducts() {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease",
    });
  }, []);
  return (
    <div className="relative w-full h-[150px] lg:h-[300px] mt-[5rem]">
      <img
        src={banner}
        alt=""
        className="w-full h-[150px] lg:h-[300px] object-fit"
      />
      <div className="absolute inset-0 bg-[#00000080]"></div>
      <div className="career-title absolute top-8 lg:top-12 right-0 w-[50vw] lg:w-[45vw] flex flex-col justify-center">
        <h1
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className="text-[#fff] text-[16px] lg:text-[50px] ps-[0.1rem] pe-[0.2rem] lg:p-0 font-[700] font-[Cairo]"
        >
          {t("latestProducts").split("\n").map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
        </h1>
        <Link
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
          to={"/products"}
        >
          <button className="bg-[#af1f23] text-[12px] lg:text-[20px] font-[400] font-[Cairo] rounded-full text-[#fff] px-3 py-1 mt-2">
            {t("ShowMore")}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default LatestProducts;
