import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useProducts } from "../../Context/ProductsContext";

function Cards() {
  const { id } = useParams();
  const { getProductsSubBrands, isProductsSubBrands } = useProducts();

  useEffect(() => {
    getProductsSubBrands(id);
  }, []);

  const [hoveredSubBrandId, setHoveredSubBrandId] = useState(null);

  return (
    <div
      className="grid grid-rows-3 grid-cols-1 lg:grid-cols-5 gap-5 w-full justify-items-center"
      dir="ltr"
    >
      {isProductsSubBrands?.data?.map((subBrand) => (
        <div
          key={subBrand.id}
          className="relative"
          onMouseEnter={() => setHoveredSubBrandId(subBrand.id)}
          onMouseLeave={() => setHoveredSubBrandId(null)}
        >
          <img
            src={subBrand.image_url}
            alt=""
            className="w-[300px] h-[300px]"
          />
          {hoveredSubBrandId === subBrand.id && (
            <div className="absolute inset-0 grid place-items-center bg-[#00000030]">
              <div className="items-center flex flex-col gap-2 justify-center">
                <Link to={`/order/ ${subBrand.name}`}>
                  <button className="bg-[#0A66B0] text-[18px] lg:text-[15px] text-[#FFF] font-[Cairo] font-[600] py-1 px-5">
                    Ask your price
                  </button>
                </Link>
                <Link to={subBrand.catalog_url} target="_blank">
                  <button className="bg-[#AF1F24] text-[18px] lg:text-[15px] text-[#FFF] font-[Cairo] font-[600] py-1 px-5">
                    Download
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      ))
      }
    </div >
  );
}

export default Cards;
