import React from "react";

function SectionOne({ isNewsDetails }) {

  return (
    <div className="flex flex-col lg:flex-row mt-[8rem] lg:mt-[10rem] items-center gap-10 lg:gap-10 w-full">
      <div data-aos="fade-in"
        data-aos-delay="500"
        className="flex flex-col lg:w-[50vw] lg:flex-row-reverse justify-end items-center gap-7">
        <img src={isNewsDetails?.image_url} alt=""
          className="w-full lg:w-full cursor-pointer"
        />
      </div>
      <div
        data-aos="fade-left"
        data-aos-delay="500"
        className="flex flex-col text-start gap-[1rem] lg:gap-6 lg:w-[50vw]"
      >
        <h1 className="text-[#0A66B0] text-[20px] lg:text-[40px] font-[Cairo] font-[700]">
          {isNewsDetails?.title}
        </h1>
        <p className="text-[#141414] text-[16px] lg:text-[24px] font-[Cairo] font-[400]" style={{ textAlign: "justify" }}>
          {isNewsDetails?.excerpt}
        </p>
        <span className="border"></span>
        <p className="text-[#141414] text-[10px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]"></span>{isNewsDetails?.date}
        </p>
      </div>
    </div>
  );
}

export default SectionOne;
