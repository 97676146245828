import React, { useEffect, useState } from "react";
import ar from "../../Assets/Images/ar.png";
import en from "../../Assets/Images/en.png";
import logo from "../../Assets/Images/logo.png";
import Select from "react-select";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function NavBarMobile() {
  const { t, i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "en";
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const languageOptions = [
    {
      value: "en",
      label: (
        <img
          src={en}
          alt="en"
          className="w-[33px] h-[33px] md:w-[33px] md:h-[33px] lg:w-[33px] lg:h-[33px] rounded-[50%] border-2 m-auto"
        />
      ),
    },
    {
      value: "ar",
      label: (
        <img
          src={ar}
          alt="ar"
          className="w-[33px] h-[33px] md:w-[33px] md:h-[33px] lg:w-[33px] lg:h-[33px] rounded-[50%] border-2 m-auto"
        />
      ),
    },
  ];

  const navData = [
    { name: t("Home"), link: "/home" },
    { name: t("About"), link: "/about" },
    { name: t("Services"), link: "/services" },
    { name: t("Projects"), link: "/projects" },
    { name: t("Products"), link: "/products" },
    { name: t("News"), link: "/news" },
    { name: t("Careers"), link: "/careers" },
    { name: t("Contact"), link: "/contact" },
  ];
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (link) => {
    setMenuOpen(!isMenuOpen);
    setSelectedOption(link.link);
  };
  const [selectedLanguage, setSelectedLanguage] = useState(
    initialLanguage == "en" ? languageOptions[0] : languageOptions[1]
  );
  document.body.dir = initialLanguage == "en" ? "ltr" : "rtl";
  const [isScrolled, setIsScrolled] = useState(false);
  const [, setForceUpdate] = useState(false);
  useEffect(() => {
    // Set i18next configuration dynamically based on the selected language
    i18n.init({
      backend: {
        loadPath: `/locales/${initialLanguage}/translation.json`,
      },
      lng: initialLanguage,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // Not needed for React
      },
    });
  }, [initialLanguage]);
  const handleLanguageChange = (selectedOption) => {
    const selectedLanguage = selectedOption.value;
    localStorage.setItem("language", selectedLanguage);

    if (selectedLanguage === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
    setSelectedLanguage(selectedOption);
    i18n.changeLanguage(selectedLanguage);
    window.location.reload(false);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setIsScrolled(position > window.innerHeight * 0.1);
  };

  const forceRerender = () => {
    setForceUpdate((prev) => !prev);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    forceRerender();
  }, [i18n.language]);

  return (
    <div
      id="sidebar"
      className={`fixed top-0 left-0 right-0 bg-[#fff] shadow-lg z-50 ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <div className="lg:hidden relative flex items-center justify-between px-4">
        <Link to={"/home"}>
          <img
            src={logo}
            alt="Logo"
            className="w-32 h-16 md:w-[30vw] md:h-[9vh] object-contain"
          />
        </Link>
        <FontAwesomeIcon
          icon={faBars}
          size="lg"
          className="text-black cursor-pointer"
          onClick={toggleMenu}
        />
      </div>
      <div
        className={`flex flex-col justify-between p-3 ${
          isMenuOpen ? "flex" : "hidden"
        }`}
      >
        {navData.map((link) => (
          <NavLink
            to={link.link}
            key={link.link}
            className={
              "text-black  px-4 md:text-[18px] lg:text-[18px] font-[400] font-[Cairo]   not-italic relative inline-block"
            }
          >
            {link.name}
          </NavLink>
        ))}
      </div>
      {/* Language Selection for Mobile - Hidden by default */}
      <div
        className={`w-[22vw] md:w-[12vw] m-auto  my-1 px-4 ${
          isMenuOpen ? "" : "hidden"
        }`}
      >
        <div className="select-container">
          <Select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            options={languageOptions}
            isSearchable={false}
            hideSelectedOptions={true}
            styles={{
              control: (provided) => ({
                ...provided,
                border: "none",
                width: "90px",
                color: "#000",
                backgroundColor: "transparent",
              }),
              menu: (provided) => ({
                ...provided,
                overflow: "visible",
              }),
              option: (provided) => ({
                ...provided,
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }),
              singleValue: (provided) => ({
                ...provided,
                display: "flex",
                alignItems: "center",
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: "#000",
              }),
            }}
          />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default NavBarMobile;
