import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";
import NavBarMobile from "../Components/NavBar/NavBarMobile";

function Layout() {
  return (
    <div className="">
      <NavBar />
      <NavBarMobile />
      <Outlet />
      <div className="mt-20 lg:mt-0">
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
