import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useProducts } from "../../Context/ProductsContext";
import { useTranslation } from "react-i18next";

function SectionThree() {
  const { t } = useTranslation();

  const { getProductsBrands, isProductsBrands } = useProducts();

  useEffect(() => {
    getProductsBrands();
  }, []);

  return (
    <div className="flex flex-col gap-[1rem] w-full">
      <div className="flex justify-between items-center">
        <h1 className="text-[#0A66B0] text-[22px] lg:text-[44px] font-[Cairo] font-[600]">
          {t("Brands")}
        </h1>
        {/* <Link to={"/allCategories"}>
          <h3 className="text-[#AAAAAA] text-[13px]  lg:text-[18px] font-[Cairo] font-[600] flex items-center gap-2">
            View All <img src={arrow} alt="" className="w-[15px] lg:w-[25px]" />
          </h3>
        </Link> */}
      </div>
      <div className="flex flex-wrap justify-center items-center">
        {isProductsBrands?.data?.map((brand) => (
          <Link to={"/brand/" + brand.id} key={brand.id}>
            <div className="flex flex-col gap-5 items-start cursor-pointer">
              <img
                src={brand.image_url}
                alt=""
                className="w-full h-[350px] lg:w-[300px] lg:h-[300px]"
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SectionThree;
