import React from "react";
import pr5 from "../../Assets/Images/1618915671610.jpg";

function SectionFour() {
  return (
    <div className="my-[4rem] w-full">
      <img src={pr5} alt="" className="w-full h-[200px] lg:h-[400px] object-fit" />
    </div>
  );
}

export default SectionFour;
