import React, { useEffect } from "react";
import Form from "../Components/ApplyNow/Form";
import Banner from "../Components/ApplyNow/Banner";
import { useParams } from "react-router-dom";

function ApplyNow() {
  const { title } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
      <Banner title={title} />
      <Form title={title} />
    </div>
  );
}

export default ApplyNow;
