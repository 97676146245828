import React, { useEffect } from "react";
import Banner from "../Components/Contact/Banner";
import Cards from "../Components/Contact/Cards";
import Form from "../Components/Contact/Form";
import { useHome } from "../Context/HomeContext";

function Contact() {
  const { getContact, isContact } = useHome("");

  useEffect(() => {
    getContact();
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
      <Banner />
      <Cards isContact={isContact?.data} />
      <Form />
    </div>
  );
}

export default Contact;
